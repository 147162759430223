import React, { useEffect, useMemo, useRef, useState } from 'react';
import Heading from '../../../molecules/Heading/Heading';
import Section from '../../../molecules/Section/Section';
import Image from '../../../utils/OptimizedImage';
import { Wrapper, customSectionStyle } from '../styles/VerticalHero.styles';
import { getTag } from '../../../utils/getTag';

const VerticalHero = ({
  background,
  headingBlock,
  headingKickerTag,
  headingTag,
  id,
  nextSectionId,
  reference,
}) => {
  const referenceRef = useRef();
  const [height, setHeight] = useState(0);
  const [color, setColor] = useState();
  const { heading, kicker, subhead, buttons } = headingBlock[0] ?? {};
  const headingTagFallback = kicker ? 'h2' : 'h1';

  const Reference = useMemo(() => {
    switch (reference[0]?.__typename) {
      case 'DatoCmsWebsiteImage':
        return (
          <Image
            image={reference[0]?.image?.gatsbyImageData}
            alt={reference[0]?.image?.alt}
            title={reference[0]?.image?.title}
          />
        );
      case 'DatoCmsLottie':
        return <></>;
      default:
        return <></>;
    }
  }, [reference]);

  useEffect(() => {
    const handler = () => {
      const current = referenceRef?.current;
      const sectionHeight = current?.offsetParent?.clientHeight;
      const referenceCenter = current?.offsetTop + current?.clientHeight / 2;

      const nextSection = document.getElementById(nextSectionId);
      const nextSectionStyle =
        nextSection && window.getComputedStyle(nextSection);
      const bgColor = nextSectionStyle?.backgroundColor;

      setHeight(sectionHeight - referenceCenter);
      setColor(bgColor);
    };

    handler();
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section
      id={id}
      bgColor={background}
      innerStyle={customSectionStyle(height, color)}
      hasSvgHexes
    >
      <Wrapper>
        <Heading
          heading={heading}
          kicker={kicker}
          subhead={subhead}
          alignment="center"
          kickerTag={headingKickerTag ? getTag(headingKickerTag) : 'h1'}
          headingTag={headingTag ? getTag(headingTag) : headingTagFallback}
          headingSize={['md', 'lg', 'xxl']}
          buttons={buttons}
        />
        {reference[0] && <div ref={referenceRef}>{Reference}</div>}
      </Wrapper>
    </Section>
  );
};

export default VerticalHero;
