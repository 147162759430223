import styled, { css } from 'styled-components';

import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { paddingY } from '../../../styles/atoms/spacing';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 35px;

  ${atMinWidth.sm`
    gap: 56px;
  `}
`;

export const customSectionStyle = (height, color) => css`
  ${atMinWidth.md`
    ${paddingY(139, 1)}
  `}

  ${atMinWidth.lg`
    ${paddingY(147, 1)}
  `}

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${height}px;
    background-color: ${color || colors.base.white};
    z-index: -1;
  }
`;
